<template>
    <div class="badge">
        <span v-if="type == 'claim'" :class="['badge__title', `badge__title--${type}`]"><router-link :to="`/panel/claim/${id}/view`">{{ `${dateString} Дело '${title}'` }}</router-link></span>
        <span v-if="type == 'appeal'" :class="['badge__title', `badge__title--${type}`]"><router-link :to="`/panel/appeal/${id}/view`">{{ `${dateString} Обращение '${title}'` }}</router-link></span>
        <span v-if="type == 'payment'" :class="['badge__title', `badge__title--${type}`]">{{ `${dateString} Оплата '${title}'` }}</span>
    </div>
</template>

<script>
export default {
    name: 'badge',
    props: {
        id: Number,
        type: String,
        title: String,
        date: Object,
    },
    computed: {
        dateString() {
            return `${this.date.format('HH:mm')}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.badge {
    font-size: 14px;
    margin: 0 0 3px 0;
    width: 100%;
    position: relative;
    line-height: 16px;
    &__title {
        border-radius: 5px;
        padding: 0 3px;
        display: block;
        width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover {
            display: inline;
            background: #fff;
            font-weight: bold;
        }
        &--info {
            background-color: #eef5f1;
            color: #97bb9f;
        }
        &--error {
            background-color: #fceced;
            color: #c1868c;
        }
        &--warning {
            background-color: #faead0;
            color: #d2ae81;
        }
    }
}
</style>