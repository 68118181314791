<template>
    <div class="payment-page">
        <div class="main-h1">Мой календарь</div>
        <Calendar :events="calendarEvents" />
        <div class="card" v-if="claims.length">
            <div class="card-body">
                <div class="card-body-title">Список дел</div>
                <claim-list :list="claims"/>
            </div>
        </div>
        <div class="card" v-if="appeals.length">
            <div class="card-body">
                <div class="card-body-title">Список обращений</div>
                <appeal-list :list="appeals" v-if="appeals.length"/>
            </div>
        </div>
        <div class="card" v-if="payments.length">
            <payment-list :list="payments" @updated="getPayments"/>
        </div>
    </div>
</template>

<script>
import paymentList from '../../../components/payment/list';
import claimList from '../../../components/claim/list.vue';
import appealList from '../../../components/appeal/list.vue';
import Calendar from '../../../components/calendar/index';
export default {
    name: "paymentPage",
    components:{paymentList,claimList,appealList,Calendar},
    data() {
        return {
            payments:[],
            claims:[],
            appeals:[],
            calendarEvents: [],
            type: ['claim', 'payment', 'notification', 'appeal'],
        }
    },
    methods: {
        calendarFill() {
            this.calendarEvents = [];
            this.claims.forEach(item => {
                this.calendarEvents.push({ id: item.id, type: 'claim', title: item.title, date: this.$moment(item.stage_dl)});
            })
            this.appeals.forEach(item => {
                this.calendarEvents.push({ id: item.id, type: 'appeal', title: item.title, date: this.$moment(item.stage_dl)});
            })
            this.payments.filter(item => item.status_id != 3).forEach(item => {
                this.calendarEvents.push({ id: item.id, type: 'payment', title: item.title, date: this.$moment(item.status_dl)});
            })
        },
        getPayments() {
            this.$api.get(`payment/my`).then(res => {
                if (res.data.success) {
                    this.payments = res.data.data
                    this.calendarFill();
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getClaims() {
            this.$api.get(`claim/my`).then(res => {
                if (res.data.success) {
                    this.claims = res.data.data
                    this.calendarFill();
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getAppeals() {
            this.$api.get(`appeal/my`).then(res => {
                if (res.data.success) {
                    this.appeals = res.data.data
                    this.calendarFill();
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    },
    created() {
        this.getClaims();
        this.getPayments();
        this.getAppeals();
    }
}
</script>

<style scoped>

</style>